'use client';

import { useRef } from 'react';
import { useIntl } from 'react-intl';
import PageSectionType from '@alltrails/analytics/enums/PageSectionType';
import { useLanguageRegionCode } from '@alltrails/language';
import useLogWebSectionViewed from '@alltrails/layout/hooks/useLogWebSectionViewed';
import FormattedMessage from '@alltrails/layout/components/FormattedMessage';
import FrequentlyAskedQuestion from '@alltrails/layout/components/FrequentlyAskedQuestion/FrequentlyAskedQuestion';
import FaqPageStructuredData from '@alltrails/layout/components/FrequentlyAskedQuestion/FaqPageStructuredData';
import { renderToStaticMarkup } from 'react-dom/server';
import { FaqDataType, LocationDetailsResponseType, PageFilterType } from '../../types/locationDetailsPages/shared';
import SectionHeader from '../SectionHeader';
import { useSelector } from '../../utils/redux';
import PageSection from '../layouts/PageSection';
import { FaqFilterType } from './types';
import styles from './LocationFAQ.module.scss';
import { getBestTrailQuestion, BestTrailAnswer } from './BestTrailFaq';
import { getBikingQuestion, BikingAnswer } from './BikingFaq';
import { getCampingQuestion, CampingAnswer } from './CampingFaq';
import { getElevationQuestion, ParkElevationAnswer, LocationElevationAnswer } from './ElevationFaq';
import { getLongestTrailQuestion, LongestTrailAnswer } from './LongestTrailFaq';
import { NationalParksFaqPageFilter, getNationalParkQuestion, NationalParkAnswer } from './NationalParksFaq/NationalParksFaq';
import { getParkAggregatesQuestion, ParkAggregatesAnswer } from './ParkAggregatesFaq';
import { getPopularDifficultTrailQuestion, PopularDifficultTrailAnswer } from './PopularDifficultFaq';
import { getRunningQuestion, RunningAnswer } from './RunningFaq';
import { getTypesOfTrailsQuestion, TypesOfTrailsAnswer } from './TypesOfTrailsFaq';
import { getAnalyticsPageType } from '@/utils/getAnalyticsPageType';

type Props = {
  type: LocationDetailsResponseType;
  name: string;
  faqData: FaqDataType;
  pageFilterUid?: PageFilterType;
  isBot?: boolean;
  id: number;
  hasSpacingExperiment?: boolean;
};

type FaqEntry = {
  question: string;
  answer: React.ReactNode;
  stringAnswer?: string;
  key: string;
};

const FAQ_ALLOWED_FOR_FILTERS = [
  'backpacking',
  'bike-touring',
  'birding',
  'camping',
  'fishing',
  'hiking',
  'horseback-riding',
  'mountain-biking',
  'off-road-driving',
  'road-biking',
  'rock-climbing',
  'scenic-driving',
  'skiing',
  'snowshoeing',
  'trail-running',
  'via-ferrata',
  'walking',
  'cross-country-skiing',
  'ada',
  'beach',
  'cave',
  'city-walk',
  'dogs',
  'forest',
  'historic-site',
  'hot-springs',
  'kids',
  'lake',
  'partially-paved',
  'paved',
  'pub-crawl',
  'rails-trails',
  'river',
  'strollers',
  'waterfall',
  'wildlife'
];

const LocationFAQ = ({ type, name, faqData, pageFilterUid, isBot, id, hasSpacingExperiment }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const pageType = getAnalyticsPageType(type);
  const languageRegionCode = useLanguageRegionCode();
  const displayMetric = useSelector(state => state.context.displayMetric);
  useLogWebSectionViewed(ref, PageSectionType.FAQ, pageType, { threshold: 0.4 });

  if (pageFilterUid && !FAQ_ALLOWED_FOR_FILTERS.includes(pageFilterUid)) {
    return null;
  }

  const {
    longestTrail,
    bestTrail,
    popularDifficultTrail,
    mostElevationGainTrails,
    faqTrailTypes,
    faqNationalParks,
    faqParkAggregates,
    campingTrails,
    runningTrails,
    bikingTrails
  } = faqData;

  const pageFilter = pageFilterUid as FaqFilterType;

  const entries = [] as FaqEntry[];

  // "what national parks have hiking trails" is only shown on region and country pages
  if (type === 'location' && faqNationalParks && faqNationalParks.length > 0 && !['pub-crawl', 'city-walk'].includes(pageFilter)) {
    const nationalParksPageFilter = pageFilter as NationalParksFaqPageFilter;
    entries.push({
      question: getNationalParkQuestion(intl, name, nationalParksPageFilter),
      answer: (
        <NationalParkAnswer
          filter={nationalParksPageFilter}
          locationName={name}
          faqNationalParks={faqNationalParks}
          intl={intl}
          languageRegionCode={languageRegionCode}
        />
      ),
      key: 'faq-national-parks'
    });
  }

  // "different types of hiking trails" is only shown on city, region, country and POI pages
  if (['location', 'poi'].includes(type) && !pageFilter && faqTrailTypes && faqTrailTypes.length > 0) {
    entries.push({
      question: getTypesOfTrailsQuestion(intl, name, type),
      answer: (
        <TypesOfTrailsAnswer faqTrailTypes={faqTrailTypes} placeName={name} locationType={type} intl={intl} languageRegionCode={languageRegionCode} />
      ),
      key: 'faq-hiking-trail-types'
    });
  }

  // "best place to hike" is shown on park and POI pages
  if (bestTrail && ['park', 'poi'].includes(type)) {
    entries.push({
      question: getBestTrailQuestion(intl, name, type, pageFilter),
      answer: (
        <BestTrailAnswer
          filter={pageFilter}
          placeName={name}
          bestTrail={bestTrail}
          locationType={type}
          languageRegionCode={languageRegionCode}
          intl={intl}
          displayMetric={displayMetric}
        />
      ),
      key: 'faq-hiking-best-place'
    });
  }

  if (longestTrail) {
    entries.push({
      question: getLongestTrailQuestion(intl, name, type, pageFilter),
      answer: (
        <LongestTrailAnswer
          filter={pageFilter}
          locationName={name}
          locationType={type}
          longestTrail={longestTrail}
          languageRegionCode={languageRegionCode}
          intl={intl}
          displayMetric={displayMetric}
        />
      ),
      key: 'faq-longest-trail'
    });
  }

  const parkHasTrailsAndReviews = faqParkAggregates && faqParkAggregates.totalTrails > 0 && faqParkAggregates.reviewCount > 0;
  if (type === 'park' && parkHasTrailsAndReviews) {
    entries.push({
      question: getParkAggregatesQuestion(intl, name, pageFilter),
      answer: (
        <ParkAggregatesAnswer
          filter={pageFilter}
          locationName={name}
          faqParkAggregates={faqParkAggregates}
          intl={intl}
          languageRegionCode={languageRegionCode}
        />
      ),
      key: 'faq-park-aggregates'
    });
  }

  if (type === 'park' && mostElevationGainTrails) {
    entries.push({
      question: getElevationQuestion(intl, name, type, pageFilter),
      answer: (
        <ParkElevationAnswer
          filter={pageFilter}
          locationName={name}
          mostElevationGainTrails={mostElevationGainTrails}
          languageRegionCode={languageRegionCode}
          intl={intl}
          displayMetric={displayMetric}
        />
      ),
      key: 'faq-park-elevations'
    });
  }

  if (['location', 'poi'].includes(type) && mostElevationGainTrails) {
    entries.push({
      question: getElevationQuestion(intl, name, type, pageFilter),
      answer: (
        <LocationElevationAnswer
          filter={pageFilter}
          locationName={name}
          mostElevationGainTrails={mostElevationGainTrails}
          languageRegionCode={languageRegionCode}
          intl={intl}
          displayMetric={displayMetric}
        />
      ),
      key: 'faq-location-elevations'
    });
  }

  if (['location', 'poi'].includes(type) && popularDifficultTrail) {
    entries.push({
      question: getPopularDifficultTrailQuestion(intl, name, type, pageFilter),
      answer: (
        <PopularDifficultTrailAnswer
          filter={pageFilter}
          locationName={name}
          popularDifficultTrail={popularDifficultTrail}
          locationType={type}
          intl={intl}
          languageRegionCode={languageRegionCode}
        />
      ),
      key: 'faq-popular-difficult-trail'
    });
  }

  if (campingTrails && campingTrails.count > 0) {
    entries.push({
      question: getCampingQuestion(intl, name),
      answer: <CampingAnswer locationName={name} campingTrails={campingTrails} languageRegionCode={languageRegionCode} intl={intl} />,
      key: 'faq-camping'
    });
  }

  if (runningTrails && runningTrails.count > 0) {
    entries.push({
      question: getRunningQuestion(intl, name),
      answer: <RunningAnswer locationName={name} runningTrails={runningTrails} intl={intl} languageRegionCode={languageRegionCode} />,
      key: 'faq-running'
    });
  }

  if (bikingTrails && bikingTrails.count > 0) {
    entries.push({
      question: getBikingQuestion(intl, name),
      answer: <BikingAnswer locationName={name} bikingTrails={bikingTrails} languageRegionCode={languageRegionCode} intl={intl} />,
      key: 'faq-biking'
    });
  }

  if (entries.length === 0) {
    return null;
  }

  return (
    <PageSection hasSpacingExperiment={hasSpacingExperiment}>
      <section className={styles.locationFAQSection} ref={ref}>
        <FaqPageStructuredData faqItems={entries.map(({ question, answer }) => ({ question, answer: renderToStaticMarkup(answer) }))} />
        <div className={styles.titleSection}>
          <SectionHeader title={<FormattedMessage defaultMessage="Frequently asked questions" />} className={styles.title} />
        </div>
        <div className={styles.answers}>
          {entries.map(({ question, answer, key }) => (
            <FrequentlyAskedQuestion
              shouldRenderExpanded={isBot || entries.length < 2}
              question={question}
              answer={answer}
              key={key}
              id={id}
              pageType={pageType}
            />
          ))}
        </div>
      </section>
    </PageSection>
  );
};

export default LocationFAQ;
